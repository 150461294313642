import React from "react";
import { styled } from "@mui/material";
import { BurgerButton, ExitIcon } from "../assets";
import { logout } from "../redux/slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../components/UI/Modal";
import { useToggle } from "../utilits/hooks/useToggle";
import Button from "../components/UI/Button";
import DisplayFlex from "./DisplayFlex";
import back from "../assets/images/back.png";

const Header = ({ children }) => {
  const { role } = useSelector((state) => state.login);
  const changedRole = role.toLowerCase();
  const params = useParams();
  const activeNavPanel = params["*"];
  const cutString = activeNavPanel.substring(0, 6);
  const existingOrderById = activeNavPanel.substring(7);
  const { isActive, setActive } = useToggle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logout({ navigate }));
  };

  const navigateBackHandler = () => {
    navigate(`/${changedRole}/${cutString}`);
  };

  return (
    <StyledHeader>
      <StyledBurgerButton onClick={() => setActive("sideBar")} />
      {existingOrderById ? (
        <ButtonBack onClick={navigateBackHandler} src={back} />
      ) : null}
      <StyledExitIcon onClick={() => setActive("ExitModal")} />
      <Modal
        padding="30px"
        onClose={() => setActive("nothing")}
        isOpen={isActive === "ExitModal"}
      >
        <Text>Вы уверены что хотите выйти?</Text>
        <DisplayFlex margin="10px 0 0 0" gap="10px">
          <Button
            backgroundColor="#778899"
            onClick={() => setActive("nothing")}
          >
            Отмена
          </Button>
          <Button backgroundColor="#00CED1" onClick={logoutHandler}>
            Да
          </Button>
        </DisplayFlex>
      </Modal>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled("header")`
  position: fixed;
  display: flex;
  z-index: 100;
  right: 0;
  width: 83vw;
  height: 60px;
  padding: 10px 10px 0 30px;
  background-color: white;

  -webkit-box-shadow: 0px 9px 12px -3px rgba(34, 60, 80, 0.21);
  -moz-box-shadow: 0px 9px 12px -3px rgba(34, 60, 80, 0.21);
  box-shadow: 0px 9px 12px -3px rgba(34, 60, 80, 0.21);
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const StyledBurgerButton = styled(BurgerButton)`
  display: none;
  cursor: pointer;
  @media (max-width: 500px) {
    display: flex;
    width: 25px;
    height: 25px;
    margin-top: 10px;
  }
`;
const StyledExitIcon = styled(ExitIcon)`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 20px;
`;
const Text = styled("p")`
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 1.2rem;
`;
const ButtonBack = styled("img")`
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 500;
  @media (max-width: 500px) {
    margin: 6px 0 0 10px;
    width: 30px;
    height: 30px;
  }
`;
