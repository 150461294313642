import React, { useState } from "react";
import { styled } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { DeleteIcon } from "../../assets";

function ImagePicker({ setImage, image, NewItem, setNewItem, touched }) {
  const [file, setFile] = useState(null);

  const onDrop = (file) => {
    setFile(
      Object.assign(file[0], {
        preview: URL.createObjectURL(file[0]),
      })
    );
    setImage(file[0], "image");
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const clearImage = () => {
    setFile(null);
    setNewItem({ ...NewItem, image: "" });
    delete NewItem["image"];
  };

  return (
    <ContainerImageItem error={Boolean(!image) && touched}>
      {file?.preview || image ? <DeleteIcon onClick={clearImage} /> : null}
      <ContainerDrop {...getRootProps()}>
        <input {...getInputProps()} />
        {file?.preview || image ? (
          <DropImage src={file?.preview || image} />
        ) : (
          <Text>Добавить фото</Text>
        )}
      </ContainerDrop>
    </ContainerImageItem>
  );
}

const ContainerImageItem = styled("div")`
  position: relative;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid gray;")};
  svg {
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
  }
`;
const ContainerDrop = styled("div")`
  width: 170px;
  height: 170px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const DropImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Text = styled("p")`
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  text-align: center;
  color: #a9a8a8;
`;

export default ImagePicker;
