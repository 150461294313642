import React from "react";
import { NotificationIcon } from "../../assets";
import { styled } from "@mui/material";
import { axiosInstance } from "../../api/axiosInstance";
import { useDispatch } from "react-redux";
import {
  errorToastifyAction,
  loadingToastifyAction,
  successToastifyAction,
} from "../../redux/slices/toastify-slice";

const Notificaiton = ({ ItemById, storeId }) => {
  const dispatch = useDispatch();

  let dataForNotification = {
    title: ItemById?.name,
    desc: ItemById?.description
      ? ItemById?.description?.substr(0, 50)
      : ItemById?.name,
    itemId: `${ItemById?.id}`,
  };

  const sendNotification = async () => {
    dispatch(loadingToastifyAction());
    try {
      const response = await axiosInstance.post(
        "/aliboss/api/core/notification/",
        dataForNotification
      );
      return dispatch(successToastifyAction("Уведомление отправлено"));
    } catch (error) {
      return dispatch(errorToastifyAction("Что-то пошло не так"));
    }
  };

  return !Boolean(storeId) ? (
    <NotificationButton onClick={sendNotification} />
  ) : null;
};

export default Notificaiton;

const NotificationButton = styled(NotificationIcon)`
  width: 48px;
  height: 43px;
  border-radius: 15px;
  background-color: #ffff00;
  padding: 7px;
  margin: 10px 0 0 10px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #fefe89;
  }
  &:active {
    background-color: #bebe12;
  }
`;
