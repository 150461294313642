import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { AppRoutes } from "./routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES, USER_KEY } from "./utilits/constants/general";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { localStorageHelper } from "./utilits/helpers/general";
import { useDispatch, useSelector } from "react-redux";
import { getIsAuthorized, loginQueryAction } from "./redux/slices/auth-slice";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthorized = useSelector(getIsAuthorized);
  const { role } = useSelector((state) => state.login);

  useEffect(() => {
    const USER_DATA = localStorageHelper.get(USER_KEY);
    if (USER_DATA?.token) {
      dispatch(
        loginQueryAction({
          data: USER_DATA,
          navigate,
          pathname: location.pathname,
        })
      );
    }
    setIsLoading(false);
  }, []);

  if (isLoading) return null;

  if (location.pathname === "/") {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return (
    <div className="App">
      <AppRoutes role={role} isAuthorized={isAuthorized} />
      <ToastContainer />
    </div>
  );
}

export default App;
