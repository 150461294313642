import { configureStore } from "@reduxjs/toolkit";
import { AuthSlice } from "./slices/auth-slice";
import { scrollSlice } from "./slices/scrollSlice";
import { toastSlice } from "./slices/toastify-slice";

export const store = configureStore({
  reducer: {
    login: AuthSlice.reducer,
    toastify: toastSlice.reducer,
    scrollPosition: scrollSlice.reducer,
  },
});
