import React from "react";
import { styled } from "@mui/material";
import SideBar from "./SideBar/SideBar";
import Header from "./Header";

const ControlPanelLayout = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <Header />
      <SubContainer>{children}</SubContainer>
    </Container>
  );
};

export default ControlPanelLayout;

const Container = styled("div")`
  min-height: 100vh;
  display: flex;
  gap: 60px;
  background-color: #f4f6f9;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const SubContainer = styled("div")`
  padding: 6vw 0 0 19vw;
  @media (max-width: 500px) {
    padding: 19vw 0 0 0;
  }
`;
