import React, { useState, useEffect, useRef } from "react";
import DisplayFlex from "../../layout/DisplayFlex";
import { OrdersTableHead } from "../../utilits/constants/general";
import TableOrders from "./TableOrders";
import { axiosInstance } from "../../api/axiosInstance";
import { styled } from "@mui/material";
import { Input } from "../UI/Input/Input";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { InputAdornment, IconButton } from "@mui/material";
import { SearchIcon } from "../../assets";
import FilterByUsers from "./FilterByUsers";

const Orders = () => {
  const { storeId } = useSelector((state) => state.login);
  const [ordersData, setOrdersData] = useState([]);
  const [countOrders, setCountOrders] = useState(-1);
  const [isOptovik, setIsOptovik] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(true);
  const inputRef = useRef();
  const widthWindow = window.screen.width < 501;
  const [filterValues, setFilterValues] = useState({
    searchByStore: "",
    dueDate: "",
    startDate: "",
    user_id: "",
    totalCostOrdersByUser: 0,
  });

  const id = typeof storeId === "undefined" ? "=" : `=${storeId}`;
  const isOptovikValue = isOptovik.get("isOptovik");
  let readyStartDate = "=";
  let readyDueDate = "=";

  if (!Boolean(filterValues.startDate)) {
    readyStartDate = "=";
  } else if (Boolean(filterValues.startDate)) {
    readyStartDate = `=${filterValues.startDate}`;
  }

  if (!Boolean(filterValues.dueDate)) {
    readyDueDate = "=";
  } else if (Boolean(filterValues.dueDate)) {
    readyDueDate = `=${filterValues.dueDate}`;
  }

  let optovik = "=";
  if (isOptovikValue === "All") {
    optovik = "=";
  } else if (isOptovikValue === "True") {
    optovik = "=True";
  } else if (isOptovikValue === "False") {
    optovik = "=False";
  }

  const getAllFilterValues = (key, value) => {
    setOrdersData([]);
    setQuery(true);
    setFilterValues({ ...filterValues, [key]: value });
    setCountOrders(-1);
    setCurrentPage(1);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setOrdersData([]);
      setQuery(true);
      setCountOrders(-1);
      setCurrentPage(1);
    }, 450);

    return () => clearTimeout(delayDebounceFn);
  }, [filterValues.searchByStore]);

  const getIsOptovikUsersHandler = (optovik) => {
    setQuery(true);
    setOrdersData([]);
    setIsOptovik({ isOptovik: optovik });
    setCountOrders(-1);
    setCurrentPage(1);
  };

  const clearAll = () => {
    setQuery(true);
    setOrdersData([]);
    setCountOrders(-1);
    setCurrentPage(1);
    setFilterValues({
      searchByStore: "",
      dueDate: "",
      startDate: "",
      user_id: "",
      totalCostOrdersByUser: 0,
    });
    setIsOptovik({ isOptovik: "All" });
    inputRef.current.value = "";
  };
  const getOrdersData = async () => {
    try {
      const response = await axiosInstance(
        `/aliboss/api/core/order/?store${id}&isoptovik${optovik}&start_date${readyStartDate}&end_date${readyDueDate}&search=${filterValues?.searchByStore}&page=${currentPage}&user_id=${filterValues?.user_id}`
      );
      setCountOrders(response.data.count);
      if (response.data.count > 19) {
        setCurrentPage((prevState) => prevState + 1);
      }
      if (filterValues.user_id) {
        let result = response.data.results.reduce(function (sum, elem) {
          return sum + elem.totalCost;
        }, 0);
        setFilterValues({ ...filterValues, totalCostOrdersByUser: result });
      }
      return setOrdersData([...ordersData, ...response.data.results]);
    } catch (error) {
      return console.log(error);
    } finally {
      return setQuery(false);
    }
  };

  useEffect(() => {
    if (ordersData.length !== countOrders && query) {
      getOrdersData();
    }
  }, [query, getIsOptovikUsersHandler, getAllFilterValues, clearAll]);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return function () {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        90 &&
      ordersData?.length !== countOrders
    ) {
      setQuery(true);
    }
  };

  return (
    <Container FD="column">
      <DisplayFlex
        padding={widthWindow ? "0 0 0 20px" : ""}
        AI={widthWindow ? "100%" : ""}
        FD="column"
        gap="6px"
      >
        <DisplayFlex
          FD={widthWindow ? "column" : ""}
          margin="0 0 10px 0"
          gap={widthWindow ? "15px " : "27px"}
          width={widthWindow ? "94%" : "70vw"}
        >
          <Input
            value={filterValues.searchByStore}
            fullWidth
            onChange={(e) =>
              setFilterValues({
                ...filterValues,
                searchByStore: e.target.value,
              })
            }
            placeholder="Поиск по магазину"
            endAdornment={
              <StyledIconWrapper position="end">
                <IconButton className="icon">
                  <SearchIcon />
                </IconButton>
              </StyledIconWrapper>
            }
          />

          <FilterByUsers
            inputRef={inputRef}
            getAllFilterValues={getAllFilterValues}
          />
          <Select
            value={isOptovikValue}
            onChange={(e) => getIsOptovikUsersHandler(e.target.value)}
          >
            <option value="All">Все заказы</option>
            <option value="False">Заказы в розницу</option>
            <option value="True">Оптом</option>
          </Select>
        </DisplayFlex>
        <DisplayFlex
          FW={widthWindow && "wrap"}
          gap="8px"
          width="100%"
          AI="center"
        >
          <DisplayFlex width={widthWindow ? "46%" : ""} FD="column">
            <Label> Заказы от:</Label>
            <Input
              value={filterValues.startDate}
              onChange={(e) => getAllFilterValues("startDate", e.target.value)}
              type="date"
            />
          </DisplayFlex>
          <DisplayFlex width={widthWindow ? "46%" : ""} FD="column">
            <Label> Заказы до:</Label>
            <Input
              onChange={(e) => getAllFilterValues("dueDate", e.target.value)}
              value={filterValues.dueDate}
              type="date"
            />
          </DisplayFlex>
          <ClearButton onClick={clearAll} backgroundColor="#6495ED">
            Очистить все
          </ClearButton>
        </DisplayFlex>
      </DisplayFlex>
      <CountOrders>
        Количество заказов:{" "}
        <span>{countOrders !== -1 ? countOrders : null}</span>
      </CountOrders>
      <TableOrders
        fetching={query && ordersData.length !== countOrders}
        data={ordersData}
        listThead={OrdersTableHead}
        totalCostByUserOrder={
          filterValues.totalCostOrdersByUser
            ? filterValues.totalCostOrdersByUser
            : null
        }
      />
    </Container>
  );
};

export default Orders;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
`;
const CountOrders = styled("p")`
  margin: 17px 0 0 10px;
  span {
    color: #3685fc;
    font-weight: 600;
    font-size: 1rem;
    margin: 15px 20px 0 8px;
  }
`;
const Select = styled("select")`
  width: 190px;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0;
  }
  height: 45px;
  background: white;
  color: gray;
  padding: 5px 10px 5px 5px;
  font-size: 1rem;
  border: 1px solid #c7c7cc;
  border-radius: 7px;
  margin-left: 10px;
  option {
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
  }
`;
const StyledIconWrapper = styled(InputAdornment)(() => ({
  margin: 0,
  paddingRight: "3px",
  "& .icon svg": {
    width: "25px",
    height: "25px",
  },
  "& .icon:hover": {
    background: "rgba(0, 0, 0, 0.01)",
    transition: "background 0.3s linear",
  },
}));
const Label = styled("label")`
  color: #8b8989;
`;
const ClearButton = styled("button")`
  width: 130px;
  height: 45px;
  font-size: 0.9rem;
  background-color: #6495ed;
  color: white;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
  margin: 22px 0 0 0;
  @media (max-width: 500px) {
    margin: 5px 0 0 0;
  }
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.4;
  }
`;
