import React from "react";
import { Input } from "../UI/Input/Input";
import { styled, keyframes } from "@mui/material";
import { SearchIcon } from "../../assets";
import { InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";
import { axiosInstance } from "../../api/axiosInstance";
import { fadeIn } from "react-animations";

const FilterByUsers = ({ getAllFilterValues, inputRef }) => {
  const [users, setUsers] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);

  const getAllUsers = async (value) => {
    setShowDropDown(true);
    try {
      const response = await axiosInstance(
        `/aliboss/api/regularaccount/?search=${value}`
      );
      return setUsers(response.data);
    } catch (error) {
      return console.log(error);
    }
  };

  const getInputValueAndCallGetAllUsers = (value) => {
    getAllUsers(value);
  };

  const SelectUserAndGetById = (id, name, e) => {
    e.stopPropagation();
    inputRef.current.value = name;
    getAllFilterValues("user_id", id);
    setShowDropDown(false);
  };

  const functionClick = (e) => {
    e.stopPropagation();
    setShowDropDown(false);
  };

  window.document.addEventListener("click", functionClick);

  return (
    <Container>
      <Input
        ref={inputRef}
        fullWidth
        onChange={(e) => getInputValueAndCallGetAllUsers(e.target.value)}
        placeholder="Поиск по заказчику"
        endAdornment={
          <StyledIconWrapper position="end">
            <IconButton className="icon">
              <SearchIcon />
            </IconButton>
          </StyledIconWrapper>
        }
      />
      {showDropDown ? (
        <ContainerResultUsers>
          <span>
            Результат по поиску:<strong>{users.count} </strong>
          </span>
          {users?.results?.map((item) => {
            return (
              <UserItem
                onClick={(e) => SelectUserAndGetById(item.id, item.name, e)}
                key={item.id}
              >
                <h2>{item.name}</h2>
                <p>{item.login}</p>
              </UserItem>
            );
          })}
        </ContainerResultUsers>
      ) : null}
    </Container>
  );
};

export default FilterByUsers;

const bounceAnimation = keyframes`${fadeIn}`;

const Container = styled("div")`
  position: relative;
  width: 60vw;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const UserItem = styled("div")`
  width: 100%;
  height: 60px;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #808080;
  cursor: pointer;
  h2 {
    font-size: 0.9rem;
  }
  p {
    color: #c0c0c0;

    font-size: 0.8rem;
  }
`;
const StyledIconWrapper = styled(InputAdornment)(() => ({
  margin: 0,
  paddingRight: "3px",
  "& .icon svg": {
    width: "25px",
    height: "25px",
  },
  "& .icon:hover": {
    background: "rgba(0, 0, 0, 0.01)",
    transition: "background 0.3s linear",
  },
}));
const ContainerResultUsers = styled("div")`
  position: absolute;
  overflow: scroll;
  padding: 10px 10px 25px 10px;
  width: 100%;
  max-height: 70vh;
  border-radius: 10px;
  z-index: 100;
  background-color: white;
  -webkit-box-shadow: 1px 10px 18px 6px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 1px 10px 18px 6px rgba(34, 60, 80, 0.16);
  box-shadow: 1px 10px 18px 6px rgba(34, 60, 80, 0.16);
  animation: 0.2s ${bounceAnimation};
  span {
    margin: 5px 0 0 5px;
  }
  strong {
    color: #1e90ff;
    margin-left: 8px;
  }
`;
