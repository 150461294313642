import { createSlice } from "@reduxjs/toolkit";

export const scrollSlice = createSlice({
  name: "toast",
  initialState: {
    scrollY: 0,
  },
  reducers: {
    setScrollPosition: (state, { payload }) => {
      state.scrollY = payload;
    },
  },
});

export const { setScrollPosition } = scrollSlice.actions;
