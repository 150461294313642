import React, { forwardRef, useState } from "react";
import { IconButton, InputAdornment, styled } from "@mui/material";
import { Input } from "./Input";
import { EyeOpenedIcon, EyeClosedIcon } from "../../../assets";

export const PasswordInput = forwardRef((props, ref) => {
  const [isShowPassword, setIsShowPassword] = useState(true);

  const handleClickShowPassword = () => {
    setIsShowPassword((prevState) => !prevState);
  };

  return (
    <StyledInput
      {...props}
      ref={ref}
      autoComplete={String(false)}
      endAdornment={
        <StyledIconWrapper position="end">
          <IconButton className="icon" onClick={handleClickShowPassword}>
            {isShowPassword ? <EyeOpenedIcon /> : <EyeClosedIcon />}
          </IconButton>
        </StyledIconWrapper>
      }
      type={isShowPassword ? "password" : "text"}
    />
  );
});

const StyledInput = styled(Input)(() => ({
  "& .MuiInputBase-input": {
    paddingRight: "0",
  },
}));

const StyledIconWrapper = styled(InputAdornment)(() => ({
  margin: 0,
  paddingRight: "8px",
  "& .icon svg": {
    width: "20px",
    height: "20px",
  },
  "& .icon:hover": {
    background: "rgba(0, 0, 0, 0.01)",
    transition: "background 0.3s linear",
  },
}));
