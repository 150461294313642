import axios from 'axios';
import { GLOBAL_URL } from '../utilits/constants/general';
import { logout } from '../redux/slices/auth-slice';

const headers = {
  'Content-Type': 'multipart/form-data',
};

const axiosInstance = axios.create({ baseURL: GLOBAL_URL, headers });

let store;

export const injectStore = (_store) => {
  store = _store;
};

axiosInstance.interceptors.request.use((config) => {
  const updatedConfig = { ...config };
  return updatedConfig;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);
export { axiosInstance };
