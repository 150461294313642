import React, { useState, useEffect } from "react";
import DisplayFlex from "../../layout/DisplayFlex";
import ItemCard from "./ItemCard";
import { axiosInstance } from "../../api/axiosInstance";
import Button from "../UI/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import { useToggle } from "../../utilits/hooks/useToggle";
import Modal from "../UI/Modal";
import CreateItem from "./CreateItem";

import { styled } from "@mui/material";

const Items = ({ storeId }) => {
  const { isActive, setActive } = useToggle();
  const [ItemsData, setItemsData] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [countItems, setCountItems] = useState(-1);
  const navigate = useNavigate();
  const id = typeof storeId === "undefined" ? "=" : `=${storeId}`;
  const widthWindow = window.screen.width < 501;

  const getItemsInDataBase = async (page) => {
    try {
      const response = await axiosInstance(
        `/aliboss/api/core/item/?supplier${id}&page=${page}`
      );

      setCountItems(response.data.count);
      if (response.data.count > 19) {
        setCurrentPage((prevState) => prevState + 1);
      }
      return setItemsData([...ItemsData, ...response.data.results]);
    } catch (error) {
      return console.log(error);
    } finally {
      return setFetching(false);
    }
  };

  useEffect(() => {
    if (fetching) {
      getItemsInDataBase(currentPage);
    }
  }, [fetching]);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return function () {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        90 &&
      ItemsData?.length !== CountItems
    ) {
      setFetching(true);
    }
  };

  const navigateToItemDetail = (id) => {
    navigate(`${id}`);
  };

  return (
    <DisplayFlex AI="center" width="100%" FD="column">
      <DisplayFlex width="77vw" JK="space-between" AI="center">
        <CountItems>
          Общее количество товаров:
          <span>{countItems !== -1 ? countItems : "0"}</span>
        </CountItems>
        {Boolean(storeId) ? (
          <Button
            fontSize={widthWindow ? "0.8rem" : ""}
            onClick={() => setActive("createItem")}
            margin="10px 10px 15px 10px"
            backgroundColor="#48C9B0"
          >
            Добавить товар
          </Button>
        ) : null}
      </DisplayFlex>
      <ContainerItems>
        {ItemsData?.map((item) => {
          return (
            <ItemCard
              onClick={() => navigateToItemDetail(item.id)}
              priceItem={item.cost}
              imageItem={item.image}
              nameItem={item.name}
            />
          );
        })}
      </ContainerItems>
      <Modal
        fullWidth="500px"
        padding="30px"
        onClose={() => setActive("nothing")}
        isOpen={isActive === "createItem"}
      >
        <CreateItem
          setCountItems={setCountItems}
          ItemsData={ItemsData}
          closeModal={() => setActive("nothing")}
          storeId={storeId}
        />
      </Modal>
      <DisplayFlex
        padding="15px"
        width={widthWindow ? "100vw" : "75vw"}
        JK="center"
      >
        {fetching && ItemsData?.length !== CountItems ? (
          <CircularProgress />
        ) : null}
      </DisplayFlex>
    </DisplayFlex>
  );
};

export default Items;

const CountItems = styled("p")`
  margin: 10px 0 15px 0;
  span {
    font-weight: 600;
    color: #6495ed;
    margin-left: 8px;
  }
`;
const ContainerItems = styled("div")`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 500px) {
    width: 100%;
    gap: 10px 15px;
  }
`;
