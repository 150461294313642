import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import { axiosInstance } from "../../api/axiosInstance";
import DisplayFlex from "../../layout/DisplayFlex";
import { Input } from "../UI/Input/Input";
import { InputAdornment, IconButton } from "@mui/material";
import { SearchIcon } from "../../assets";
import TableUsers from "./TableUsers";
import { UsersTableHead } from "../../utilits/constants/general";
import { useSearchParams } from "react-router-dom";

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetching] = useState(true);
  const [userCount, setUserCount] = useState(-1);
  const [isOptovik, setIsOptovik] = useSearchParams();
  const [inputValue, setInputValue] = useState("");
  const optovik = isOptovik.get("isOptovik");
  const widthWindow = window.screen.width < 501;

  const getIsOptovikUsersHandler = (optovik) => {
    setFetching(true);
    setUsersData([]);
    setCurrentPage(1);
    setIsOptovik({ isOptovik: optovik });
    setUserCount(-1);
  };
  const getInputValue = (value) => {
    setFetching(true);
    setInputValue(value);
    setUsersData([]);
    setUserCount(-1);
    setCurrentPage(1);
  };
  const getUsersInDataBase = async (isOptovik, searchValue) => {
    try {
      const response = await axiosInstance(
        `/aliboss/api/regularaccount/?isoptovik${isOptovik}&search${searchValue}&page=${currentPage}`
      );
      setUserCount(response.data.count);
      if (response.data.count > 19) {
        setCurrentPage((prevState) => prevState + 1);
      }
      return setUsersData([...usersData, ...response.data.results]);
    } catch (error) {
      return console.log(error);
    } finally {
      return setFetching(false);
    }
  };

  useEffect(() => {
    if (fetching && optovik === "True" && usersData.length !== userCount) {
      getUsersInDataBase(`=True`, `=${inputValue}`);
    } else if (
      fetching &&
      optovik === "False" &&
      usersData.length !== userCount
    ) {
      getUsersInDataBase("=False", `=${inputValue}`);
    } else if (fetching && !optovik && usersData.length !== userCount) {
      getUsersInDataBase("=", `=${inputValue}`);
    }
  }, [fetching, getIsOptovikUsersHandler]);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return function () {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        90 &&
      usersData.length !== userCount
    ) {
      setFetching(true);
    }
  };

  return (
    <DisplayFlex FD="column">
      <DisplayFlex
        FD={widthWindow ? "column" : ""}
        padding={widthWindow ? "8px" : ""}
        gap="10px"
      >
        <Input
          onChange={(e) => getInputValue(e.target.value)}
          placeholder="Найти"
          endAdornment={
            <StyledIconWrapper position="end">
              <IconButton className="icon">
                <SearchIcon />
              </IconButton>
            </StyledIconWrapper>
          }
        />

        <Select
          onChange={(e) => getIsOptovikUsersHandler(e.target.value)}
          value={optovik}
        >
          <option value="">Все</option>
          <option value="False">Обычные клиенты</option>
          <option value="True">Оптовики</option>
        </Select>
      </DisplayFlex>
      <UserCount>
        Количество пользователей:{" "}
        <span>{userCount === -1 ? "0" : userCount}</span>
      </UserCount>
      <TableUsers
        setUsersData={setUsersData}
        listThead={UsersTableHead}
        usersData={usersData}
        fetching={fetching && usersData.length !== userCount}
      />
    </DisplayFlex>
  );
};

export default Users;

const UserCount = styled("p")`
  margin: 10px;
  span {
    color: #3685fc;
    font-weight: 600;
  }
`;
const StyledIconWrapper = styled(InputAdornment)(() => ({
  margin: 0,
  paddingRight: "3px",
  "& .icon svg": {
    width: "25px",
    height: "25px",
  },
  "& .icon:hover": {
    background: "rgba(0, 0, 0, 0.01)",
    transition: "background 0.3s linear",
  },
}));
const Select = styled("select")`
  width: 150px;
  height: 45px;
  background: white;
  color: gray;
  padding: 5px 10px 5px 5px;
  font-size: 1rem;
  border: 1px solid gray;
  border-radius: 7px;
  @media (max-width: 500px) {
    width: 100%;
  }
  option {
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
  }
`;
