import { styled } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../api/axiosInstance";
import FormatDate from "../UI/FormatDate";
import { statusValue } from "../../utilits/constants/general";
import ReactToPrint from "react-to-print";
import DisplayFlex from "../../layout/DisplayFlex";
import Button from "../UI/Button";
import { useToggle } from "../../utilits/hooks/useToggle";
import EditOrder from "./EditOrder";
import Modal from "../UI/Modal";
import { OrderItem } from "./OrderItem";
import printIcon from "../../assets/images/print.png";
import editIcon from "../../assets/images/edit.png";

const OrderDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [orderById, setOrderById] = useState({});
  const { orderId } = useParams();
  const componentRef = useRef();
  const { isActive, setActive } = useToggle();
  const widthWindow = window.screen.width < 501;

  const getOrderById = async () => {
    try {
      const { data } = await axiosInstance(
        `/aliboss/api/core/order/${orderId}/`
      );
      return setOrderById(data);
    } catch (error) {
      return console.log(error);
    }
  };

  useEffect(() => {
    getOrderById();
  }, []);

  const closeModal = () => {
    setActive("nothing");
    getOrderById();
  };

  return (
    <>
      <Container>
        <OrderList ref={componentRef}>
          <DisplayFlex JK="center">
            <LogoStore src={orderById.storeLogo} alt={orderById.storeName} />
          </DisplayFlex>
          <li>
            <p>Магазин:</p> <span>{orderById.storeName}</span>
          </li>
          <li>
            <p>Дата:</p> <FormatDate date={orderById.date} />
          </li>
          <li>
            <p>Статус :</p>
            <span>{statusValue[orderById.status]}</span>
          </li>
          <li>
            <p>Адресс доставки:</p> <span>{orderById.address}</span>
          </li>
          <li>
            <p>Коментарий:</p> <span>{orderById.comment}</span>
          </li>
          <li>
            <p>Номер телефона:</p> <span>{orderById.phone}</span>
          </li>
          <li>
            <p>Итого:</p>
            <span>{orderById.totalCost}</span>
          </li>
          <li>
            <p>Бонусами:</p>
            <span>{orderById.bonus}</span>
          </li>
          <li>
            <p>Статус оплаты:</p>
            <span>{orderById.pay_status ? "Оплачено" : "Не оплачено"}</span>
          </li>
          {orderById.items?.map((item) => {
            return (
              <OrderItem
                quantity={item.quantity}
                orderItem={item.item}
                key={item.id}
              />
            );
          })}
        </OrderList>
        <DisplayFlex margin="30px 0 0 0" FD="column" gap="10px">
          <ReactToPrint
            trigger={() => (
              <Button
                width={widthWindow ? "50px" : ""}
                backgroundColor={"#0098af"}
              >
                {widthWindow ? (
                  <img
                    style={{ marginLeft: "4px" }}
                    src={printIcon}
                    alt="trash"
                  />
                ) : (
                  "Распечатать"
                )}
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button
            width={widthWindow ? "50px" : ""}
            onClick={() => setActive("editModal")}
            backgroundColor={"#FFA500"}
          >
            {widthWindow ? (
              <img style={{ marginLeft: "6px" }} src={editIcon} alt="penchil" />
            ) : (
              "Редактироать"
            )}
          </Button>
        </DisplayFlex>
        <Modal
          padding="30px 40px 20px 40px"
          fullWidth="40vw"
          onClose={closeModal}
          isOpen={isActive === "editModal"}
        >
          <EditOrder
            closeModal={closeModal}
            orderById={orderById}
            setOrderById={setOrderById}
          />
        </Modal>
      </Container>
    </>
  );
};

export default OrderDetail;

const Container = styled("div")`
  width: 65vw;
  min-height: 700px;
  display: flex;
  gap: 10px;
  padding: 0 0 30px 0;
  @media (max-width: 500px) {
    width: 100%;
    gap: 5px;
    padding: 0 8px 0 8px;
  }
`;
const OrderList = styled("ul")`
  display: flex;
  flex-direction: column;
  min-width: 45vw;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  img {
    max-width: 300px;
    max-height: 80px;
    margin-bottom: 20px;
  }
  li {
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    min-width: 400px;
    &:nth-child(5) {
      min-height: 30px;
    }
    &:nth-child(10) {
      margin-bottom: 30px;
    }
    p {
      width: 190px;
      font-weight: 700;
    }
    span {
      max-width: 300px;
      color: #696969;
      word-wrap: break-word;
    }
  }
  @media (max-width: 500px) {
    width: 80vw;
    font-size: 0.7rem;
    li {
      display: flex;
      flex-direction: column;
      gap: 0;
      min-width: 250px;
      max-width: 250px;
    }
  }
  @media print {
    padding: 30px 130px 30px 130px;
    font-family: "Montserrat", sans-serif;
    li {
      font-size: 20px;
    }
    img {
      margin-bottom: 30px;
    }
    p {
      width: 400px;
      font-weight: 700;
    }
    span {
      color: #696969;
    }
  }
`;
const LogoStore = styled("img")`
  max-width: 300px;
  max-height: 80px;
  margin-bottom: 20px;
`;
