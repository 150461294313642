import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ROUTES, USER_KEY } from '../../utilits/constants/general.js';
import { localStorageHelper } from '../../utilits/helpers/general.js';
import {
  errorToastifyAction,
  loadingToastifyAction,
  successToastifyAction,
} from './toastify-slice';
import { axiosInstance } from '../../api/axiosInstance.js';

// ВОЙТИ
export const loginQuery = createAsyncThunk(
  'authorizaiton/login',
  async ({ dispatch, navigate, userData, pathname }, { rejectWithValue }) => {
    dispatch(loadingToastifyAction());
    try {
      const { data } = await axiosInstance.post(
        '/aliboss/api/login/',
        userData
      );
      dispatch(loginQueryAction({ data, navigate }));
      if (data.token) {
        if (typeof data.data[0]?.sale_type === 'undefined') {
          navigate(`${ROUTES.ADMIN}/users`);
        }
        if (typeof data.data[0]?.sale_type === 'number') {
          navigate(`${ROUTES.STORE}/orders`);
        }
        localStorageHelper.set(USER_KEY, data, pathname);
      }
      return dispatch(successToastifyAction('Вы успешно вошли'));
    } catch (error) {
      return dispatch(errorToastifyAction(error.response.data.detail[0]));
    }
  }
);

// ВЫЙТИ
export const logout = createAsyncThunk('logout', async ({ navigate }) => {
  localStorageHelper.remove(USER_KEY);
  navigate(ROUTES.LOGIN);
});

const initialState = {
  accessToken: null,
  role: null,
  storeId: null,
  isAuth: false,
  loading: false,
  sale_type: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginQueryAction(state, { payload }) {
      state.isAuth = true;
      state.accessToken = payload.data.token;
      if (payload.pathname === '/' || payload.pathname === '/login') {
        if (typeof payload?.data.data[0]?.sale_type === 'undefined') {
          payload.navigate(`${ROUTES.ADMIN}/users`);
        }
        if (typeof payload?.data.data[0]?.sale_type === 'number') {
          payload.navigate(`${ROUTES.STORE}/orders`);
        }
      }
      state.role = Boolean(payload?.data.data[0]?.sale_type)
        ? 'STORE'
        : 'ADMIN';
      state.storeId = payload.data.data[0]?.id;
      state.sale_type = payload?.data?.data[0]?.sale_type;
    },
  },
  extraReducers: {
    [loginQuery.pending]: (state) => {
      state.loading = true;
    },
    [loginQuery.fulfilled]: (state) => {
      state.loading = false;
    },
    [loginQuery.rejected]: (state) => {
      state.loading = false;
    },
    [logout.fulfilled]: (state, action) => {
      state = initialState;
    },
  },
});

export const { loginQueryAction } = AuthSlice.actions;
export const getIsAuthorized = (state) => state.login.isAuth;
