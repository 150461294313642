import { toast } from "react-toastify";

export function toastifyHelper(idToast, type, message) {
  return toast.update(idToast, {
    type,
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    isLoading: false,
    render: message,
  });
}

export const localStorageHelper = {
  get: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  set: (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key) => {
    return localStorage.removeItem(key);
  },
};
