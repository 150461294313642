import React, { useState } from "react";
import { styled } from "@mui/material";

const Switcher = ({ isOptovik, toggle }) => {
  return (
    <ParentBlock onClick={toggle} active={isOptovik}>
      <Ball active={isOptovik} />
    </ParentBlock>
  );
};

export default Switcher;

const ParentBlock = styled("div")`
  width: 45px;
  height: 24px;
  border-radius: 20px;
  background-color: #64c76c;
  background-color: ${(props) => (props.active ? "#64c76c" : "#eaeaeb")};
  padding: 1.8px 0 0 2.5px;
  cursor: pointer;
`;

const Ball = styled("div")`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-left: ${(props) => (props.active ? "19px" : "")};
  transition: 0.2s;
  -webkit-box-shadow: 1px 1px 5px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 1px 1px 5px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 1px 1px 5px 2px rgba(34, 60, 80, 0.16);
`;
