import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { statusValue } from "../../utilits/constants/general";
import { CancelIcon, CompleteIcon } from "../../assets";
import FormatDate from "../UI/FormatDate";
import DisplayFlex from "../../layout/DisplayFlex";
import { useDispatch } from "react-redux";
import { setScrollPosition } from "../../redux/slices/scrollSlice";

const TableOrders = ({ data, fetching, listThead, totalCostByUserOrder }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateAndSetScrollPosition = (path) => {
    navigate(`${path}`);
    dispatch(setScrollPosition(window.scrollY));
  };

  return (
    <StyledTable>
      <tbody>
        <tr>
          {listThead.map((item) => {
            return (
              <th key={item}>
                <p>{item}</p>
              </th>
            );
          })}
        </tr>
        {data?.map((item, index) => {
          return (
            <OrdersItem key={item.id} itemIndex={index % 2 !== 0}>
              <td onClick={() => navigateAndSetScrollPosition(item.id)}>
                <span>{item?.storeName}</span>
              </td>
              <td>
                <span>{item?.address ? item.address : "Не указано"}</span>
              </td>
              <td>
                <span>
                  <FormatDate date={item.date} />
                </span>
              </td>
              <td>
                <span>{statusValue[item.status]}</span>
              </td>
              <td>
                <span>{item?.totalCost}</span>
              </td>
              <td>{item.isoptovik ? <CompleteIcon /> : <CancelIcon />}</td>
            </OrdersItem>
          );
        })}
        <tr>
          <DisplayFlex padding="0 0 10px 0" JK="center">
            {fetching ? <CircularProgress /> : null}

            {totalCostByUserOrder ? (
              <TotalCost>
                Общая сумма заказов:<span>{totalCostByUserOrder} </span>
              </TotalCost>
            ) : null}
          </DisplayFlex>
          {data?.length === 0 && !fetching ? (
            <h3>Ивините нечего не найдено</h3>
          ) : null}
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default TableOrders;

const StyledTable = styled("table")`
  border-radius: 15px;
  border-collapse: collapse;
  width: 75vw;
  @media (max-width: 500px) {
    width: 100%;
    font-size: 0.5rem;
  }
  font-size: 0.8rem;
  -webkit-box-shadow: 1px 4px 8px 5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 1px 4px 8px 5px rgba(34, 60, 80, 0.2);
  box-shadow: 1px 4px 8px 5px rgba(34, 60, 80, 0.2);
  margin: 20px 0 40px 0;
  th {
    height: 50px;
    text-align: start;
    &:first-of-type {
      min-width: 16vw;
      @media (max-width: 500px) {
        width: 7vw;
        font-size: 0.5rem;
      }
      margin-left: 19px;
    }
    &:nth-of-type(2) {
      width: 16.7vw;
      @media (max-width: 500px) {
        width: 22vw;
      }
    }
    &:nth-of-type(3) {
      min-width: 15.5vw;
      max-width: 15.5vw;
      @media (max-width: 500px) {
        min-width: 18vw;
        max-width: 18vw;
      }
    }
    &:nth-of-type(4) {
      min-width: 7vw;
      max-width: 7vw;
      text-align: center;
      @media (max-width: 500px) {
        min-width: 12vw;
        max-width: 12vw;
      }
    }
    &:nth-of-type(5) {
      width: 8.5vw;
      @media (max-width: 500px) {
        width: 9vw;
      }
    }
  }
  p {
    color: #3685fc;
    font-weight: 600;
    font-size: 1rem;
    margin: 15px 20px 0 8px;
    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
  }
  tr {
    &:first-of-type {
      display: flex;
    }
  }
  h3 {
    color: #626567;
    margin: 20px;
    text-align: center;
  }
`;
const TotalCost = styled("p")`
  color: #626567 !important;
  span {
    font-size: 1.2rem;
    color: #3685fc;
    font-weight: 600;
    margin-left: 10px;
  }
`;
const OrdersItem = styled("tr")`
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 50px;
  background-color: ${({ itemIndex }) => (itemIndex ? "#f2f2f2" : "white")};
  padding: 0 13px 0 0;
  @media (max-width: 500px) {
    font-size: 0.5rem;
  }
  &:last-child {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
  td {
    &:first-of-type {
      cursor: pointer;
      min-width: 16vw;
      margin-left: 27px;
      color: #3685fc;
      font-weight: 600;
      font-size: 0.9rem;
      @media (max-width: 500px) {
        font-size: 0.5rem;
        word-wrap: break-word;
        max-width: 16vw;
      }
    }
    &:nth-of-type(2) {
      min-width: 15vw;
      max-width: 15vw;
      word-wrap: break-word;
      margin-right: 25px;
    }
    &:nth-of-type(3) {
      min-width: 15vw;
      max-width: 15vw;
      word-wrap: break-word;
      @media (max-width: 500px) {
        min-width: 16vw;
        max-width: 16vw;
      }
    }
    &:nth-of-type(4) {
      min-width: 6vw;
      max-width: 6vw;
      text-align: center;
      @media (max-width: 500px) {
        min-width: 15vw;
        max-width: 15vw;
      }
    }
    &:nth-of-type(5) {
      min-width: 130px;
      min-width: 8vw;
      text-align: center;
      margin-right: 10px;
    }
    &:nth-of-type(6) {
      min-width: 100px;
      min-width: 8vw;
      display: flex;
      justify-content: center;
    }
  }
`;
