import React, { useState, useEffect } from "react";
import DisplayFlex from "../../layout/DisplayFlex";
import Button from "../UI/Button";
import { Input } from "../UI/Input/Input";
import { styled } from "@mui/material";
import { axiosInstance } from "../../api/axiosInstance";
import TextareaAutosize from "react-textarea-autosize";
import ImagePicker from "../UI/ImagePicker";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  errorToastifyAction,
  loadingToastifyAction,
  successToastifyAction,
} from "../../redux/slices/toastify-slice";

const EditItem = ({ setItemById, ItemById, closeModal, getItemById }) => {
  const [category, setCategory] = useState([]);
  const [changedItem, setChangedItem] = useState({});
  const dispatch = useDispatch();
  const [subCategory, setSubCategory] = useState([]);

  const AllFieldsAreFilled =
    ItemById?.image &&
    ItemById?.description.trim().length > 0 &&
    ItemById?.cost > 0 &&
    ItemById?.name.length > 0 &&
    ItemById?.category &&
    ItemById?.subcategory
      ? true
      : false;

  const getCheckedValue = (key) => {
    setChangedItem({ ...changedItem, [key]: !ItemById.issale });
    return setItemById({ ...ItemById, [key]: !ItemById.issale });
  };

  const getCategoriesQuery = async () => {
    try {
      const { data } = await axiosInstance(`/aliboss/api/category/`);
      return setCategory(data);
    } catch (error) {
      return console.log(error.message);
    }
  };

  const getSubCategoriesQuery = async (id) => {
    try {
      const { data } = await axiosInstance(
        `/aliboss/api/subcategory/?category=${id}`
      );
      return setSubCategory(data);
    } catch (error) {
      return console.log(error);
    }
  };

  const getTypeTextInputValue = (value, key) => {
    setChangedItem({ ...changedItem, [key]: value });
    setItemById({ ...ItemById, [key]: value });
  };

  const saveChangedItemById = async () => {
    dispatch(loadingToastifyAction());
    try {
      const response = await axiosInstance.patch(
        `/aliboss/api/core/item/${ItemById.id}/`,
        changedItem
      );
      getItemById();
      closeModal();
      if (Object.keys(changedItem).length > 0) {
        dispatch(successToastifyAction("Успешно изменено"));
      } else {
        dispatch(successToastifyAction("Cохранено"));
      }
      return null;
    } catch (error) {
      return dispatch(errorToastifyAction("Что пошло не так"));
    }
  };

  const cancelChanged = () => {
    getItemById();
    closeModal();
  };
  const checkingForFields = () => {
    if (AllFieldsAreFilled) {
      saveChangedItemById();
    } else {
      toast.error("Заполните все поле", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    getCategoriesQuery();
  }, []);

  useEffect(() => {
    getSubCategoriesQuery(ItemById?.category?.id || ItemById?.category);
  }, [ItemById.category]);

  return (
    <>
      <FormContainer>
        <DisplayFlex gap="10px" FD="column">
          <DisplayFlex JK="space-between" padding="0 30px 0 0">
            <Label>Фото товара:</Label>
            <ImagePicker
              setNewItem={setItemById}
              NewItem={ItemById}
              setImage={getTypeTextInputValue}
              image={ItemById.image}
              touched
            />
          </DisplayFlex>
          <Label htmlFor="name">Название товара</Label>
          <Input
            error={ItemById.name?.trim()?.length === 0}
            onChange={(e) => getTypeTextInputValue(e.target.value, "name")}
            id="name"
            value={ItemById?.name}
          />
          <Label htmlFor="description">Описание</Label>
          <StyledTextAreaAutoSize
            error={ItemById.description?.trim()?.length === 0}
            onChange={(e) =>
              getTypeTextInputValue(e.target.value, "description")
            }
            id="description"
            value={ItemById?.description}
          />
          <Label htmlFor="category">Категория товара:</Label>
          <Select
            error={!ItemById.category}
            onChange={(e) => getTypeTextInputValue(e.target.value, "category")}
            id="category"
          >
            {category.map((item) => {
              return (
                <option
                  selected={item.id === ItemById?.category?.id}
                  key={item.id}
                  value={item.id}
                >
                  {item.nameRus}
                </option>
              );
            })}
          </Select>
          <Select
            error={!ItemById.subcategory}
            onChange={(e) =>
              getTypeTextInputValue(e.target.value, "subcategory")
            }
            id="subcategory"
          >
            {subCategory.map((item) => {
              return (
                <option
                  selected={item.id === ItemById?.subcategory?.id}
                  key={item.id}
                  value={item.id}
                >
                  {item.nameRus}
                </option>
              );
            })}
          </Select>
          <DisplayFlex margin="8px 0 0 0" JK="space-between" AI="center">
            <Label htmlFor="cost">Цена:</Label>
            <Input
              inputProps={{ min: 1 }}
              type="number"
              error={!ItemById?.cost}
              onChange={(e) => getTypeTextInputValue(e.target.value, "cost")}
              id="cost"
              value={ItemById?.cost}
            />
          </DisplayFlex>
          <DisplayFlex>
            <Label htmlFor="status_pay">Акционный товар?:</Label>
            <CheckBox
              checked={ItemById.issale}
              onChange={() => getCheckedValue("issale")}
              id="status_pay"
              type="checkbox"
            />
          </DisplayFlex>
          <DisplayFlex JK="space-between" AI="center">
            {ItemById?.issale ? (
              <>
                <Label htmlFor="costSale">Акционная цена:</Label>
                <Input
                  inputProps={{ min: 1 }}
                  type="number"
                  onChange={(e) =>
                    getTypeTextInputValue(e.target.value, "costSale")
                  }
                  id="costSale"
                  value={ItemById?.costSale}
                />
              </>
            ) : null}
          </DisplayFlex>
        </DisplayFlex>
      </FormContainer>
      <ContainerButton>
        <Button onClick={cancelChanged} width="150px" backgroundColor="#708090">
          Отмена
        </Button>
        <Button
          onClick={checkingForFields}
          type="submit"
          width="150px"
          backgroundColor="#20B2AA"
        >
          Сохранить
        </Button>
      </ContainerButton>
    </>
  );
};

export default EditItem;

const FormContainer = styled("form")`
  overflow: scroll;
  min-height: 500px;
  max-height: 500px;
  padding-bottom: 20px;
`;
const Label = styled("label")`
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
`;
const Select = styled("select")`
  width: 100%;
  height: 45px;
  background: white;
  padding: 5px 10px 5px 5px;
  font-size: 1rem;
  border: 1px solid #c7c7cc;
  border-radius: 7px;
  outline: none;
  &:focus {
    border: 1px solid #679aff;
  }
  option {
    display: flex;
    white-space: pre;
    min-height: 20px;
  }
`;
const CheckBox = styled("input")`
  width: 23px;
  height: 23px;
  margin-left: 7px;
`;
const StyledTextAreaAutoSize = styled(TextareaAutosize)`
  padding: 8px;
  font-size: 1.1rem;
  border-width: 0.3px;
  border-radius: 8px;
  min-height: 46px;
  outline: none;
  border: ${(props) => (props.error ? "1px solid red " : "1px solid #c7c7cc;")};
  &:focus {
    border: ${(props) =>
      props.error ? "1px solid red " : "1px solid #679aff;"};
  }
`;
const ContainerButton = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
