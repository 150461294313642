import { Route, Routes } from "react-router-dom";
import { ROUTES } from "../utilits/constants/general";
import LoginPage from "../pages/LoginPage";
import { PrivateRoute } from "./PrivateRoutes";
import ControlPanelLayout from "../layout/ControlPanel";
import AdminRoutes from "./AdminRoutes";
import StoreRoutes from "./StoreRoutes";
import { useSelector } from "react-redux";

export const AppRoutes = ({ isAuthorized }) => {
  const { storeId } = useSelector((state) => state.login);

  return (
    <Routes>
      <Route path={`${ROUTES.LOGIN}/*`} element={<LoginPage />} />
      <Route
        path={`${ROUTES.ADMIN}/*`}
        element={
          <PrivateRoute
            isAuthorized={isAuthorized}
            COMPONENT={
              <ControlPanelLayout>
                <AdminRoutes />
              </ControlPanelLayout>
            }
            fallbackPath={ROUTES.LOGIN}
          />
        }
      />
      <Route
        path={`${ROUTES.STORE}/*`}
        element={
          <PrivateRoute
            isAuthorized={isAuthorized}
            COMPONENT={
              <ControlPanelLayout>
                <StoreRoutes storeId={storeId} />
              </ControlPanelLayout>
            }
            fallbackPath={ROUTES.LOGIN}
          />
        }
      />
      <Route path="/*" element={<h1>Извините страница не найдено</h1>} />
    </Routes>
  );
};
