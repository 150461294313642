import React from "react";

const FormatDate = ({ date }) => {
  const formatDate = new Date(date);

  const newFormat = new Date(formatDate).toLocaleString("ru", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  return <span>{newFormat}</span>;
};

export default FormatDate;
