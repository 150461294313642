import React, { useState } from "react";
import DisplayFlex from "../../layout/DisplayFlex";
import Button from "../UI/Button";
import { Input } from "../UI/Input/Input";
import { styled } from "@mui/material";
import { axiosInstance } from "../../api/axiosInstance";
import { useDispatch } from "react-redux";
import {
  loadingToastifyAction,
  successToastifyAction,
} from "../../redux/slices/toastify-slice";
import { listStatus } from "../../utilits/constants/general";

const EditOrder = ({ orderById, setOrderById, closeModal }) => {
  const dispatch = useDispatch();
  const [changedOrder, setChangedOrder] = useState({});

  const getTypeTextInputValue = (value, key) => {
    const newOrderById = { ...orderById };
    newOrderById[key] = value;
    setChangedOrder({ ...changedOrder, [key]: value });
    return setOrderById(newOrderById);
  };

  const getCheckedValue = (key) => {
    const newOrderById = { ...orderById };
    newOrderById[key] = !orderById.pay_status;
    setChangedOrder({ ...changedOrder, [key]: !orderById.pay_status });
    return setOrderById(newOrderById);
  };

  const saveChangesHandler = async (e) => {
    e.preventDefault();
    dispatch(loadingToastifyAction());
    try {
      const response = await axiosInstance.put(
        `/aliboss/api/core/order/${orderById.id}/`,
        changedOrder
      );

      dispatch(successToastifyAction("Успешно изменен"));
      return closeModal();
    } catch (error) {
      return console.log(error);
    }
  };

  return (
    <form>
      <DisplayFlex gap="10px" FD="column">
        <Label htmlFor="address">Адрес</Label>
        <Input
          onChange={(e) => getTypeTextInputValue(e.target.value, "address")}
          id="address"
          value={orderById.address}
        />
        <Label htmlFor="number">Номер</Label>
        <Input
          onChange={(e) => getTypeTextInputValue(e.target.value, "phone")}
          id="number"
          value={orderById.phone}
        />
        <Label htmlFor="comment">Комментарий</Label>
        <Input
          onChange={(e) => getTypeTextInputValue(e.target.value, "comment")}
          id="comment"
          value={orderById.comment}
        />
        <Label htmlFor="order_status">Статус заказа</Label>
        <Select
          onChange={(e) => getTypeTextInputValue(e.target.value, "status")}
          id="order_status"
        >
          {listStatus.map((item) => {
            return (
              <option
                selected={item.status === orderById.status}
                value={item.status}
              >
                {item.title}
              </option>
            );
          })}
        </Select>
        <DisplayFlex>
          <Label htmlFor="status_pay">Оплачено:</Label>
          <CheckBox
            onChange={(e) => getCheckedValue("pay_status")}
            checked={orderById.pay_status}
            id="status_pay"
            type="checkbox"
          />
        </DisplayFlex>
      </DisplayFlex>
      <DisplayFlex JK="flex-end" gap="15px" margin="20px 0 0 0">
        <Button
          onClick={() => closeModal("nothing")}
          width="150px"
          backgroundColor="#708090"
        >
          Отменить
        </Button>
        {Object.keys(changedOrder).length > 0 ? (
          <Button
            onClick={saveChangesHandler}
            type="submit"
            width="150px"
            backgroundColor="#20B2AA"
          >
            Сохранить
          </Button>
        ) : null}
      </DisplayFlex>
    </form>
  );
};

export default EditOrder;

const Label = styled("label")`
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
`;
const Select = styled("select")`
  width: 100%;
  height: 45px;
  background: white;
  padding: 5px 10px 5px 5px;
  font-size: 1rem;
  border: 1px solid #c7c7cc;
  border-radius: 7px;
  option {
    display: flex;
    white-space: pre;
    min-height: 20px;
  }
`;
const CheckBox = styled("input")`
  width: 23px;
  height: 23px;
  margin-left: 7px;
`;
