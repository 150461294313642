import React from "react";
import { styled } from "@mui/material";
import DisplayFlex from "../../layout/DisplayFlex";

const ItemCard = ({ imageItem, priceItem, nameItem, onClick }) => {
  return (
    <CardBlock onClick={onClick}>
      <DisplayFlex items JK="center" AI="center" heigth="200px" width="200px">
        <ImageItem image={imageItem} />
      </DisplayFlex>
      <ContainerTitle>
        <p>{nameItem}</p>
        <span>{priceItem} сом</span>
      </ContainerTitle>
    </CardBlock>
  );
};

export default ItemCard;

const CardBlock = styled("div")`
  width: 230px;
  @media (max-width: 500px) {
    width: 160px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 4px 5px 9px -2px rgba(34, 60, 80, 0.12);
  -moz-box-shadow: 4px 5px 9px -2px rgba(34, 60, 80, 0.12);
  box-shadow: 4px 5px 9px -2px rgba(34, 60, 80, 0.12);
  border-radius: 15px;
  cursor: pointer;
  background-color: white;
  span {
    font-weight: 600;
    color: #6495ed;
  }
`;
const ContainerTitle = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  min-height: 80px;
  width: 80%;
  padding: 7px;
  font-size: 1rem;
  @media (max-width: 500px) {
    font-size: 0.7rem;
    padding: 2px;
    min-height: 60px;
  }
`;
const ImageItem = styled("div")`
  width: 180px;
  height: 180px;
  @media (max-width: 500px) {
    width: 150px;
    height: 150px;
  }
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
