import * as yup from 'yup';
export const USER_KEY = 'ALI-BOSS-USER-KEY';
// export const GLOBAL_URL = "http://176.126.166.63";
export const GLOBAL_URL = 'https://alibosskg.ru';

export const ROLES = [
  {
    ADMIN: 'ADMIN',
  },
  {
    STORE: 'STORE',
  },
];

export const NAV_LIST = {
  ADMIN: [
    {
      title: 'Пользователи',
      path: 'users',
    },
    {
      title: 'Все заказы',
      path: 'orders',
    },
    {
      title: 'Товары',
      path: 'itemss',
    },
  ],
  STORE: [
    {
      title: 'Заказы',
      path: 'orders',
    },
    {
      title: 'Товары',
      path: 'itemss',
    },
  ],
};

export const ROUTES = {
  LOGIN: '/login',
  ADMIN: '/admin',
  STORE: '/store',
  USERS: {
    INDEX: 'users',
  },
  ORDERS: {
    INDEX: 'orders',
    ORDER_DETAIL: 'orders/:orderId',
  },
  ITEMS: {
    INDEX: 'itemss',
    ITEM_DETAIL: 'itemss/:itemId',
  },
};

export const statusValue = {
  1: 'Новый',
  2: 'Упаковывается',
  3: 'В пути',
  4: 'Доставлено',
  5: 'Отказ',
  6: 'Отменен',
};
export const listStatus = [
  {
    status: 1,
    title: 'Новый',
  },
  {
    status: 2,
    title: 'Упаковывается',
  },
  {
    status: 3,
    title: 'В пути',
  },
  {
    status: 4,
    title: 'Доставлено',
  },
  {
    status: 5,
    title: 'Отказ',
  },
  {
    status: 6,
    title: 'Отменен',
  },
];
export const UsersTableHead = ['Логин', 'Имя', 'Телефон номер', 'Оптовик'];
export const OrdersTableHead = [
  'Название магазина',
  'Адресс',
  'Дата',
  'Статус',
  'Сумма',
  'Оптовик',
];
export const OrdersTemporaryData = [
  {
    id: 1,
    nameStore: 'Mebelin',
    addres: '32ул.Кольбаева,Бишкек',
    date: '15 января 2023г. 13:54',
    status: 'Новый',
    totalAmount: 34333,
  },
  {
    id: 2,
    nameStore: 'Mebelin',
    addres: '32ул.Кольбаева,Бишкек',
    date: '15 января 2023г. 13:54',
    status: 'Новый',
    totalAmount: 34333,
  },
  {
    id: 3,
    nameStore: 'Mebelin',
    addres: '32ул.Кольбаева,Бишкек',
    date: '15 января 2023г. 13:54',
    status: 'Новый',
    totalAmount: 34333,
  },
  {
    id: 4,
    nameStore: 'Mebelin',
    addres: '32ул.Кольбаева,Бишкек',
    date: '15 января 2023г. 13:54',
    status: 'Новый',
    totalAmount: 34333,
  },
  {
    id: 5,
    nameStore: 'Mebelin',
    addres: '32ул.Кольбаева,Бишкек',
    date: '15 января 2023г. 13:54',
    status: 'Новый',
    totalAmount: 34333,
  },
];
export const Users = [
  {
    id: 1,
    login: 'nurgiza.babasheva.84@gmail.com',
    name: 'Nargiza Nargiza',
    phoneNumber: '+996 702 45-15-76',
    isOptovik: false,
  },
  {
    id: 2,
    login: 'nurgiza.babasheva.84@gmail.com',
    name: 'Nargiza Narigza Nargiza',
    phoneNumber: '+996 702 45-15-76',
    isOptovik: false,
  },
  {
    id: 3,
    login: 'nurgiza.babasheva.84@gmail.com',
    name: 'Nargiza',
    phoneNumber: '+996 702 45-15-76',
    isOptovik: false,
  },
  {
    id: 4,
    login: 'nurgiza.babasheva.84@gmail.com',
    name: 'Nargiza',
    phoneNumber: '+996 702 45-15-76',
    isOptovik: false,
  },
];
export const validationSchema = yup.object({
  login: yup.string().required('Обязательно поле'),
  password: yup.string().required('Обязательное поле'),
});
