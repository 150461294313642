import { InputBase, styled } from "@mui/material";
import React, { forwardRef } from "react";

export const Input = forwardRef((props, ref) => {
  return (
    <StyledInput
      {...props}
      inputRef={ref}
      classes={{ input: "input", focused: "focused", error: "error" }}
    >
      {props.children}
    </StyledInput>
  );
});

const StyledInput = styled(InputBase)(() => ({
  border: "1px solid #C7C7CC",
  borderRadius: "8px",
  fontFamily: "Montserrat, sans-serif",
  transition: "border 0.3s linear",
  background: "#FFFFFF",
  "& .input": {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    padding: "10px 15px",
    color: "#151515",
  },
  "& .input:-webkit-autofill": {
    borderRadius: "8px",
  },
  "& .base-input::placeholder": {
    color: "#A6A6A6",
    opacity: 1,
  },
  "&.focused": {
    borderColor: "#679AFF",
  },
  "&.error": {
    borderColor: "#FF3B30",
  },
}));
