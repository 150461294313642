import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastifyHelper } from "../../utilits/helpers/general";

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    toastId: null,
  },
  reducers: {
    loadingToastifyAction: (state) => {
      state.toastId = toast.loading("...Загрузка");
    },
    successToastifyAction: (state, { payload }) => {
      state.toastId = toastifyHelper(state.toastId, "success", payload);
    },
    errorToastifyAction: (state, { payload }) => {
      state.toastId = toastifyHelper(state.toastId, "error", payload);
    },
    warningToastifyAction: (state, { payload }) => {
      state.toastId = toastifyHelper(state.toastId, "warning", payload);
    },
  },
});

export const {
  loadingToastifyAction,
  successToastifyAction,
  errorToastifyAction,
  warningToastifyAction,
} = toastSlice.actions;
