import React from "react";
import { Routes, Route } from "react-router-dom";
import Orders from "../components/Orders/Orders";
import Users from "../components/Users/Users";
import { ROUTES } from "../utilits/constants/general";
import OrderDetail from "../components/Orders/OrderDetail";
import Items from "../components/Items/Items";
import ItemDetail from "../components/Items/ItemDetail";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.USERS.INDEX} element={<Users />} />
      <Route path={ROUTES.ORDERS.INDEX} element={<Orders />} />
      <Route path={ROUTES.ORDERS.ORDER_DETAIL} element={<OrderDetail />} />
      <Route path={ROUTES.ITEMS.INDEX} element={<Items />} />
      <Route path={ROUTES.ITEMS.ITEM_DETAIL} element={<ItemDetail />} />
    </Routes>
  );
};

export default AdminRoutes;
