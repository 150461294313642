import React from "react";
import logoAliBoss from "../../assets/images/LogoAliBoss.png";
import { styled } from "@mui/material";
import { NAV_LIST } from "../../utilits/constants/general";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import { useToggle } from "../../utilits/hooks/useToggle";

const SideBar = () => {
  const { role } = useSelector((state) => state.login);
  const params = useParams();
  const activeNavPanel = params["*"];
  const cutString = activeNavPanel.substring(0, 6);
  const { isActive, setActive } = useToggle();

  return (
    <>
      <Drawer
        anchor="left"
        open={isActive === "sideBar"}
        onClose={() => setActive("nothing")}
      >
        <StyledSideBar>
          <HeaderSideBar>
            <Logo src={logoAliBoss} alt="logo" />
            <h3>Aliboss</h3>
          </HeaderSideBar>
          <ul>
            {NAV_LIST[role]?.map((item) => {
              return (
                <Link key={item.title} to={item.path}>
                  <SideBarItem active={`${item.path}` === cutString}>
                    {item.title}
                  </SideBarItem>
                </Link>
              );
            })}
          </ul>
        </StyledSideBar>
      </Drawer>
      <StyledSideBarForDesktop>
        <HeaderSideBar>
          <Logo src={logoAliBoss} alt="logo" />
          <h3>Aliboss</h3>
        </HeaderSideBar>
        <ul>
          {NAV_LIST[role]?.map((item) => {
            return (
              <Link key={item.title} to={item.path}>
                <SideBarItem active={`${item.path}` === cutString}>
                  {item.title}
                </SideBarItem>
              </Link>
            );
          })}
        </ul>
      </StyledSideBarForDesktop>
    </>
  );
};

export default SideBar;

const HeaderSideBar = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid white;
  padding: 1px 0 10px 0;
  h3 {
    color: #ffffff;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
`;
const Logo = styled("img")`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 25px;
`;
const SideBarItem = styled("li")`
  margin: 5px;
  height: 40px;
  padding: 7px 0 0 20px;
  border-radius: 10px;
  transition: 0.1s;
  background-color: ${(props) => (props.active ? "#247bfc" : "")};
  color: ${(props) => (props.active ? "#FFFFFF" : "")};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (!props.active ? "#474d52" : "")};
  }
`;
const StyledSideBar = styled("aside")`
  background-color: #31373e;
  padding-top: 10px;
  @media (max-width: 500px) {
    width: 50vw;
    height: 100%;
    z-index: 500;
  }
  ul {
    a {
      color: #b0b5bd;
      text-decoration: none;
    }
  }
`;
const StyledSideBarForDesktop = styled("aside")`
  position: fixed;
  background-color: #31373e;
  width: 17vw;
  height: 100vh;
  font-size: 1rem;
  margin: 0;
  padding: 8px 0 0 0;
  color: #b0b5bd;
  @media (max-width: 500px) {
    width: 50vw;
    height: 100%;
    z-index: 500;
    display: none;
  }
  ul {
    a {
      color: #b0b5bd;
      text-decoration: none;
    }
  }
`;
