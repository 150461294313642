import React, { useState } from "react";
import { styled } from "@mui/material";
import DisplayFlex from "../../layout/DisplayFlex";
import Modal from "../UI/Modal";

export const OrderItem = ({ orderItem, quantity }) => {
  const [activeImage, setActiveImage] = useState("");
  return (
    <StyledOrderItem>
      <img
        onClick={() => setActiveImage(orderItem.image)}
        src={orderItem.image || orderItem.imagelink}
        alt={orderItem.name}
      />
      <DisplayFlex padding="7px 0 10px 0" width="400px" FD="column">
        <p>{orderItem.name}</p>
        <DisplayFlex JK="space-between">
          <span>
            {quantity} × {orderItem.cost} C
          </span>
          <strong>{orderItem.cost * quantity} C</strong>
        </DisplayFlex>
      </DisplayFlex>
      <Modal
        onClose={() => setActiveImage("")}
        isOpen={activeImage === orderItem.image}
      >
        <img src={orderItem.image} />
      </Modal>
    </StyledOrderItem>
  );
};

const StyledOrderItem = styled("div")`
  display: flex;
  min-height: 80px;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  margin: 8px 0 8px 0;
  font-size: 1.1rem;
  div {
    p {
      max-width: 400px;
      word-wrap: break-word;
      padding: 5px 0 15px 0;
    }
    div {
      span {
        color: #989898;
      }
    }
  }
  img {
    max-width: 300px;
    max-height: 100px;
  }
  strong {
    color: #6495ed;
  }
`;
