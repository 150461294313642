import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Button, styled, IconButton, InputAdornment } from "@mui/material";
import { Input } from "../components/UI/Input/Input";
import { PasswordInput } from "../components/UI/Input/PasswordInput";
import { UserIcon } from "../assets";
import alibosLogo from "../assets/images/LogoAliBoss.png";
import { loginQuery } from "../redux/slices/auth-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { validationSchema } from "../utilits/constants/general";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.login);
  const { pathname } = useLocation();

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema,
    onSubmit: (userInfo) => {
      return dispatch(
        loginQuery({
          dispatch,
          navigate,
          userData: userInfo,
          pathname,
        })
      );
    },
  });

  const { isValid } = formik;

  return (
    <ContainerSignIn>
      <img src={alibosLogo} alt="aliboss" />
      <Form onSubmit={formik.handleSubmit}>
        <WelcomeText>Добро пожаловать в панель управления AliBoss</WelcomeText>
        <InputContainer>
          <Input
            id="login"
            label="Логин"
            value={formik.values.login}
            onChange={formik.handleChange}
            endAdornment={
              <StyledIconWrapper position="end">
                <IconButton className="icon">
                  <UserIcon />
                </IconButton>
              </StyledIconWrapper>
            }
            placeholder="Логин"
          />

          <ErrorText> {formik.errors.login}</ErrorText>

          <PasswordInput
            id="password"
            label="Password"
            value={formik.values.password}
            type="text"
            onChange={formik.handleChange}
            placeholder="Пароль"
          />
          <ErrorText passowrdError> {formik.errors.password}</ErrorText>

          <Button
            disabled={loading || !isValid}
            variant="contained"
            type="submit"
          >
            Войти
          </Button>
        </InputContainer>
      </Form>
    </ContainerSignIn>
  );
};

export default LoginPage;

const ContainerSignIn = styled("div")`
  width: 100vw;
  height: 98vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 190px;
    height: 190px;
    margin: 30px 0 10px 0;
  }
`;
const Form = styled("form")`
  width: 500px;
  height: 300px;
  @media (max-width: 500px) {
    width: 350px;
    font-size: 0.7rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  -webkit-box-shadow: 2px 8px 13px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 8px 13px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 2px 8px 13px 4px rgba(34, 60, 80, 0.2);
  p {
    font-size: 1rem;
    text-align: center;
    margin: 0;
  }
`;
const WelcomeText = styled("p")`
  @media (max-width: 500px) {
    padding: 0 10px 0 10px;
    height: 40px;
  }
`;
const InputContainer = styled("div")`
  width: 450px;
  @media (max-width: 500px) {
    width: 320px;
  }
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
`;
const StyledIconWrapper = styled(InputAdornment)(() => ({
  margin: 0,
  paddingRight: "3px",
  "& .icon svg": {
    width: "25px",
    height: "25px",
  },
  "& .icon:hover": {
    background: "rgba(0, 0, 0, 0.01)",
    transition: "background 0.3s linear",
  },
}));
const ErrorText = styled("p")`
  position: absolute;
  font-size: 1rem;
  top: ${(props) => (props.passowrdError ? "66%" : "25%")};
  left: 5px;
  color: red;
`;
