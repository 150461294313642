import { styled } from "@mui/material";
import React from "react";

const Button = ({
  active,
  width,
  hover,
  children,
  color,
  onClick,
  backgroundColor,
  margin,
  fontSize,
}) => {
  return (
    <StyledButton
      fontSize={fontSize}
      hover={hover}
      active={active}
      width={width}
      color={color}
      backgroundColor={backgroundColor}
      onClick={onClick}
      margin={margin}
    >
      {children}
    </StyledButton>
  );
};

export default React.memo(Button);

const StyledButton = styled("button")`
  width: ${(props) => (props.width ? props.width : "200px")};
  height: 40px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  border-radius: 15px;
  border: none;
  margin: ${(props) => props.margin};
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  background-color: ${(props) => props.backgroundColor};
  transition: 0.1s;
  &:hover {
    opacity: 0.7;
    background-color: ${(props) => props.hover};
  }
  &:active {
    opacity: 0.4;
    background-color: ${(props) => props.active};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
