import React, { useEffect, useState } from "react";
import { useToggle } from "../../utilits/hooks/useToggle";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material";
import { axiosInstance } from "../../api/axiosInstance";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import EditItem from "./EditItem";
import Notificaiton from "./Notificaiton";
import DisplayFlex from "../../layout/DisplayFlex";
import { useDispatch } from "react-redux";
import {
  errorToastifyAction,
  loadingToastifyAction,
  warningToastifyAction,
} from "../../redux/slices/toastify-slice";
import editIcon from "../../assets/images/edit.png";
import deleteIcon from "../../assets/images/delete.png";

const ItemDetail = ({ storeId }) => {
  const [ItemById, setItemById] = useState({});
  const { itemId } = useParams();
  const { isActive, setActive } = useToggle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const widthWindow = window.screen.width < 501;

  const getItemById = async () => {
    try {
      const response = await axiosInstance(`/aliboss/api/core/item/${itemId}/`);
      return setItemById(response.data);
    } catch (error) {
      return console.log(error.message);
    }
  };
  const deleteItemAndNavigate = async () => {
    dispatch(loadingToastifyAction());
    try {
      const response = await axiosInstance.delete(
        `/aliboss/api/core/item/${itemId}/`
      );
      navigate("/store/itemss");
      return dispatch(
        warningToastifyAction(`Вы удалили товар ${ItemById?.name}`)
      );
    } catch (error) {
      return dispatch(errorToastifyAction("Извините что-то пошло не так"));
    }
  };

  useEffect(() => {
    getItemById();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <OrderList>
        <li>
          <p>Фото товара:</p>
          <PhotoItem image={ItemById?.image} alt={ItemById?.name} />
        </li>
        <li>
          <p>Название товара:</p> <span>{ItemById?.name}</span>
        </li>
        <li>
          <p>Описание:</p>
          <span>{ItemById?.description}</span>
        </li>
        <li>
          <p>Категория :</p>
          <span>
            {ItemById?.category?.nameRus},{ItemById?.subcategory?.nameRus}
          </span>
        </li>
        <li>
          <p>Цена:</p> <span>{ItemById?.cost} сом</span>
        </li>
        <li>
          {ItemById?.issale ? (
            <>
              <p>Акционная цена:</p> <span>{ItemById?.costSale} сом</span>
            </>
          ) : null}
        </li>
      </OrderList>

      <DisplayFlex FD="column">
        <Button
          width={widthWindow ? "50px" : ""}
          margin="13px 0 0 10px"
          onClick={() => setActive("editModal")}
          backgroundColor={"#FFA500"}
        >
          {widthWindow ? (
            <img style={{ marginLeft: "6px" }} src={editIcon} alt="penchil" />
          ) : (
            "Редактироать"
          )}
        </Button>
        <Button
          width={widthWindow ? "50px" : ""}
          margin="13px 0 0 10px"
          onClick={() => setActive("deleteItem")}
          backgroundColor={"#FF4500"}
        >
          {widthWindow ? (
            <img style={{ marginLeft: "6px" }} src={deleteIcon} alt="trash" />
          ) : (
            "Удалить"
          )}
        </Button>
        <Notificaiton storeId={storeId} ItemById={ItemById} />
      </DisplayFlex>
      <Modal
        padding="30px 40px 20px 40px"
        fullWidth="40vw"
        onClose={() => setActive("nothing")}
        isOpen={isActive === "deleteItem"}
      >
        <ClarifyingText>Вы хотите удалить этот товар?</ClarifyingText>
        <DisplayFlex gap="20px">
          <Button
            backgroundColor="#778899"
            onClick={() => setActive("nothing")}
          >
            Отмена
          </Button>
          <Button onClick={deleteItemAndNavigate} backgroundColor="#FF4500">
            Удалить
          </Button>
        </DisplayFlex>
      </Modal>
      <Modal
        padding="30px 40px 20px 40px"
        fullWidth="40vw"
        onClose={() => setActive("nothing")}
        isOpen={isActive === "editModal"}
      >
        <EditItem
          getItemById={getItemById}
          ItemById={ItemById}
          setItemById={setItemById}
          closeModal={() => setActive("nothing")}
        />
      </Modal>
    </Container>
  );
};

export default ItemDetail;

const Container = styled("div")`
  width: 65vw;
  min-height: 700px;
  display: flex;
  padding-bottom: 20px;
`;
const OrderList = styled("ul")`
  display: flex;
  flex-direction: column;
  min-width: 40vw;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;

  img {
    max-width: 300px;
    max-height: 80px;
    margin-bottom: 20px;
  }
  li {
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    min-width: 400px;
    &:nth-child(4) {
      max-width: 330px;
    }
    &:nth-child(5) {
      min-height: 30px;
    }
    &:nth-child(10) {
      margin-bottom: 30px;
    }
    &:nth-child(5),
    :nth-child(6) {
      span {
        font-weight: 600;
        color: #6495ed;
      }
    }
    p {
      min-width: 190px;
      font-weight: 700;
    }
    span {
      max-width: 300px;
      color: #696969;
      word-wrap: break-word;
    }
  }
  @media (max-width: 500px) {
    min-width: 300px;
    font-size: 0.7rem;
    li {
      display: flex;
      flex-direction: column;
      gap: 0;
      min-width: 250px;
      max-width: 250px;
    }
  }
  @media print {
    padding: 30px 130px 30px 130px;
    font-family: "Montserrat", sans-serif;
    li {
      font-size: 20px;
    }
    img {
      margin-bottom: 30px;
    }
    p {
      width: 400px;
      font-weight: 700;
    }
    span {
      color: #696969;
    }
  }
`;
const PhotoItem = styled("div")`
  width: 220px;
  height: 300px;
  @media (max-width: 500px) {
    width: 150px;
    height: 230px;
  }
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
const ClarifyingText = styled("p")`
  text-align: center;
  padding: 0 0 15px 0;
`;
