import { styled } from "@mui/material";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Switcher from "../UI/Switcher";
import DisplayFlex from "../../layout/DisplayFlex";
import { axiosInstance } from "../../api/axiosInstance";
import { useDispatch } from "react-redux";
import {
  loadingToastifyAction,
  successToastifyAction,
  warningToastifyAction,
} from "../../redux/slices/toastify-slice";

const TableUsers = ({ usersData, fetching, listThead, setUsersData }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const changeRoleUserHandler = async (id, index) => {
    const existingUser = usersData.filter((item) => item.id === id);
    setLoading(true);
    dispatch(loadingToastifyAction());
    try {
      const { data } = await axiosInstance.patch(
        `http://176.126.166.63/aliboss/api/regularaccount/${id}/`,
        {
          isoptovik: !existingUser[0].isoptovik,
        }
      );
      const newUserData = [...usersData];
      newUserData[index].isoptovik = data.isoptovik;
      if (data.isoptovik) {
        dispatch(successToastifyAction(`${data.name} стал оптовиком`));
      } else {
        dispatch(warningToastifyAction(`${data.name} больше не оптовик`));
      }
      return setUsersData(newUserData);
    } catch (error) {
      return console.log(error);
    } finally {
      return setLoading(false);
    }
  };

  const switcherHandler = (id, index) => {
    if (!loading) {
      changeRoleUserHandler(id, index);
    }
  };

  return (
    <StyledTable>
      <tbody>
        <tr>
          {listThead.map((item) => {
            return (
              <th key={item}>
                <p>{item}</p>
              </th>
            );
          })}
        </tr>
        {usersData?.map((item, index) => {
          return (
            <UsersItem key={item.id} itemIndex={index % 2 !== 0}>
              <td>
                <span>{item?.login}</span>
              </td>
              <td>
                <span>{item?.name}</span>
              </td>
              <td>
                <span>{item?.phone}</span>
              </td>
              <td>
                <Switcher
                  toggle={() => switcherHandler(item.id, index)}
                  isOptovik={item.isoptovik}
                />
              </td>
            </UsersItem>
          );
        })}
      </tbody>
      <DisplayFlex padding="10px 0 10px 0" height="150px" JK="center">
        {fetching ? <CircularProgress /> : null}
      </DisplayFlex>
      {usersData?.length === 0 && !fetching ? (
        <DisplayFlex padding="10px 0 20px 0" JK="center">
          <h2>Ивините нечего не найдено</h2>
        </DisplayFlex>
      ) : null}
    </StyledTable>
  );
};

export default TableUsers;

const StyledTable = styled("table")`
  border-radius: 15px;
  border-collapse: collapse;
  width: 78vw;
  font-size: 0.8rem;
  -webkit-box-shadow: 1px 4px 8px 5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 1px 4px 8px 5px rgba(34, 60, 80, 0.2);
  box-shadow: 1px 4px 8px 5px rgba(34, 60, 80, 0.2);
  margin-bottom: 50px;
  @media (max-width: 500px) {
    font-size: 0.6rem;
  }
  th {
    height: 50px;
    text-align: start;
    &:first-of-type {
      min-width: 30vw;
      margin-left: 19px;
    }
    &:nth-of-type(2) {
      min-width: 20vw;
    }
    &:nth-of-type(3) {
      width: 18vw;
      @media (max-width: 500px) {
        min-width: 25vw;
        text-align: center;
      }
    }
  }
  p {
    color: #3685fc;
    font-weight: 600;
    font-size: 1rem;
    margin: 15px 20px 0 8px;
    @media (max-width: 500px) {
      font-size: 0.7rem;
    }
  }
  tr {
    &:first-of-type {
      display: flex;
    }
    &:last-of-type {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;
const UsersItem = styled("tr")`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: ${({ itemIndex }) => (itemIndex ? "#f2f2f2" : "white")};
  padding: 0 13px 0 0;
  td {
    &:first-of-type {
      width: 30vw;
      color: #3685fc;
      font-weight: 600;
      margin-left: 25px;
      @media (max-width: 500px) {
        min-width: 130px;
        word-wrap: break-word;
      }
    }
    &:nth-of-type(2) {
      width: 21vw;
    }
    &:nth-of-type(3) {
      width: 18vw;
      @media (max-width: 500px) {
        min-width: 100px;
        word-wrap: break-word;
      }
    }
  }
`;
